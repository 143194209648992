import { handler } from '@/http-interceptor'
import { encrypt } from "@/utils/rsaEncrypt";
import axios from "axios";
handler()


// 获取验证码
export function getVerify(data) {
  data.code = encrypt(data.phone)
  return axios.post('/mall/api/auth/register/verify', data)
}
// 手机验证码登陆
export function userLoginbycode(data) {
  return axios.post('/auth/oauth/mobile/login', data)
}

// 手机密码登陆
export function userLoginbypass(data) {
  return axios.post('/auth/oauth/h5Login', data)
}

// 手机号验证码校验用户是否存在
export function userVerify(data) {
  return axios.post('/auth/sms/verify', data)
}

// 注册
export function userRegister(data) {
  return axios.post('/auth/oauth/register', data)
}

// 重置密码
export function changePassword(data) {
  return axios.post('/auth/password/mall/reset', data)
}

//获取用户信息
export function getUserInfo() {
  return axios.get("/mall/v1/userinfo");
}


// 获取appId
export function getappId() {
  return axios.get("/auth/oauth/wx/open/config", {});
}
// wx 授权
export function wxOpenOauth(data) {
  return axios.get("/auth/oauth/wxOpenOauth?code=" + data.code, {});
}

//绑定手机号
export function wechatOpenBinding(data) {
  return axios.post("/auth/oauth/wechatBinding", data);
}
