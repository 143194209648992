import { ElMessage } from 'element-plus'

export default function () {
  const isPhone = (phone) => {
    var myreg = /^1[3-9]\d{9}$/;
    if (!myreg.test(phone)) {
      return false
    } else {
      return true
    }
  }

  // 邮箱正则
  const isEmail = (email) => {
    let regemail = /^[0-9A-Za-z_]+([-+.][0-9A-Za-z_]+)*@[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*\.[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*$/
    if (!regemail.test(email)) {
      return false
    } else {
      return true
    }
  }

  const isMessage = (type, text) => {
    // warning success error
    ElMessage({
      message: text,
      type: type,
      duration: 1000
    })
  }

  const getUrlParam = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }




  return {
    isPhone,
    isEmail,
    isMessage,
    getUrlParam
  }
}