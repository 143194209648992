// 拦截axios所有http请求，预先放入token请求头
import axios from "axios";

axios.defaults.baseURL = '/api'
axios.defaults.timeout = 8000

axios.interceptors.request.use(config => {
  let token = localStorage.getItem('token') || ''
  if (token) {
    config.headers.Authorization = "Bearer " + token
  } else {
    config.headers.Authorization = ''
  }
  return config;
});

// 响应拦截器，提前预处理响应
axios.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status == 200) {
      if (res.status == 200) {
        return res
      } else {
        // 5004 用户不存在 去注册
        if (res.status == 5004) {
          return res
        } else if (res.status == 401) {
          // Message.warning(res.msg);
          // clearHandler()
          return res;
        } else {
          // Message.warning(res.msg);
          return res;
        }
      }
    } else {
      if (res.status == 5004) {
        // Message.warning(res.msg);
        return res
      } else {
        // Message.warning(res.msg);
        // clearHandler()
        return res
      }
    }
  },
  err => {
    return err.response.data
    // if (err.response.status === 401) { // 未授权
    //   // Message.warning('请先登录');
    //   console.log(err)

    //   // clearHandler()
    // } else if (err.response.status === 400) {
    //   // Message.error(err.response.data.msg);
    //   clearHandler()
    // }
  }
);
export const createAPI = (url, method, data) => {
  const config = {};
  if (method === "get") {
    config.params = data;
  } else {
    config.data = data;
  }
  return axios({
    url,
    method,
    ...config,
  });
};

export const handler = () => {
  console.log('---')
}
// function clearHandler() {
//   // 清除全部缓存
//   localStorage.clear();
//   sessionStorage.clear();
//   ElMessage({
//     message: '登录状态过期，请重新登录',
//     type: 'error',
//     duration: 1000
//   })
// }
