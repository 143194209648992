import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'


const A_K = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0U0hA7YWFUZsrMBoK6Ssgy5H5GfmyKZuqTIxtcKqU1EzpFQsEjT8WPJyFbmcwbZTIfMZ/ufpT+tI2hHeTpzIO7evFwM0XlymeQ2QmO4aKPCEFUMmK4NlRGe5+Gp3Lzs8+D0wHbAh0NPjZ4xQBm0U52ei+FnNkRCJ/7hz759K3pQIDAQAB"

// jm
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(A_K)
  return encryptor.encrypt(txt)
}